(function ($) {
  $(document).on('product.init', '.js-product', function () {
    var $imageContainer = $('.product-full__image', $(this));

    if ($imageContainer.length) {
      Drupal.behaviors.productFullImageV1.attach($(this));
    }
  });

  Drupal.behaviors.productFullImageV1 = {
    attach: function (context) {
      // images
      var $sppImages = $('.product-full__image-carousel', context);
      var $carousel = $('.js-spp-carousel', $sppImages);

      // SPP image Carousel
      $sppImages.each(function () {
        var thisFormatter = $(this);
        var arrowsDiv = $(this).parent().find('.carousel-controls');
        var dotsDiv = thisFormatter.find('.carousel-dots');
        var sppSlide = '.js-spp-carousel__slide';
        // case for custom slides count and dots for mobile
        var settings = {
          autoplay: true,
          autoplaySpeed: 3000,
          pauseOnHover: true,
          slide: sppSlide,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          dots: true,
          appendArrows: arrowsDiv,
          appendDots: dotsDiv
        };

        // Init this carousel with our settings
        $carousel.not('.slick-initialized').slick(settings);
      });

      $(document).on('quickshop.loaded', function () {
        $carousel.slick('setPosition');
      });
    }
  };
})(jQuery);
